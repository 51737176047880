









































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Dialog from '@/components/Dialog/index.vue'
import { getDicts, getDictName } from '@/utils/dict'

@Component({
  components: {
    Dialog
  }
})
export default class extends Vue {
  @Prop({ default: false }) private isShow!: boolean
  @Prop({ default: false }) private loading!: boolean
  @Prop() private id!: number

  info: any = {}
  statusDicts: any = []

  @Watch('id')
  getid(val: number) {
    if (val !== -1) {
      this.getInfo(val)
    }
  }

  async beforeCreate() {
    // 获取相关字典
    // 访客预约状态
    this.statusDicts = await getDicts('VISIT_RESERVE_STATUS')
  }

  getInfo(id: number) {
    this.$api.visitor.getReserveInfo(id).then((res: any) => {
      const data = res.data
      if (data.success) {
        this.info = data.data
      } else {
        this.info = {
          appointDate: '',
          carNo: '',
          visitorName: '',
          houseInfo: '',
          appointName: '',
          enterImg: '',
          status: -1
        }
      }
    })
  }

  closeDialog() {
    this.$emit('close', '')
  }
  dialogConfirm() {
    this.$emit('optSuccess')
  }

  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, val)
  }
}
