


















































































































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import ImgView from '@/components/ImgView/index.vue'
import RemarkModal from '@/views/common/remark-modal.vue'
import InfoModal from './info-modal.vue'
import moment from 'moment'
// import { CommonModule } from '@/store/modules/common'
import { getDicts, getDictName } from '@/utils/dict'
import { getExportExcel } from '@/utils/export'
import { DatePicker, Dialog } from 'element-ui'
Vue.use(DatePicker)
Vue.use(Dialog)

@Component({
  components: {
    Table,
    Pagination,
    ImgView,
    RemarkModal,
    InfoModal
  }
})
export default class CarList extends Vue {
  $constants: any
  // 车辆状态
  visitReserveStatus: any = []
  tabActive = 0
  tabData = [
    {
      value: '1',
      label: '访客车辆记录'
    }
    // {
    //   value: '2',
    //   label: '已预约访客车辆'
    // }
  ]
  searchModel = {
    key: 'enterTime',
    value: null
  }
  searchModel2 = {
    key: 'carNo',
    value: null
  }
  searchModel3 = {
    key: 'appointTime',
    value: null
  }
  searchModel4 = {
    key: 'carNo',
    value: null
  }
  enterDeviceId = ''
  leaveDeviceId = ''
  listLoading = false
  tableHeader = [
    {
      key: 'enterImg',
      name: '入场照片',
      width: '9%',
      isSpecial: true
    },
    {
      key: 'carNo',
      name: '车牌号',
      width: '9%'
    },
    // {
    //   key: 'reserveId',
    //   name: '预约信息',
    //   width: '8%',
    //   isSpecial: true
    // },
    {
      key: 'enterTime',
      name: '进入时间',
      width: '13%'
    },
    {
      key: 'enterPosition',
      name: '位置',
      width: '9%'
    },
    {
      key: 'leaveImg',
      name: '出场照片',
      width: '10%',
      isSpecial: true
    },
    {
      key: 'leaveTime',
      name: '离开时间',
      width: '13%'
    },
    {
      key: 'leavePosition',
      name: '位置',
      width: '9%'
    },
    {
      key: 'remark',
      name: '备注',
      width: '8%',
      remark: true
    },
    {
      key: 'opt',
      name: '操作',
      width: '10%',
      isSpecial: true
    }
  ]
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }
  // 已预约访客车辆
  listLoading2 = false
  tableHeader2 = [
    {
      key: 'carNo',
      name: '车牌号',
      width: '14%'
    },
    {
      key: 'appointDate',
      name: '来访日期',
      width: '20%'
    },
    {
      key: 'visitorName',
      name: '访客姓名',
      width: '15%'
    },
    {
      key: 'houseInfo',
      name: '所属房屋',
      width: '16%'
    },
    {
      key: 'appointName',
      name: '预约人员',
      width: '15%'
    },
    {
      key: 'status',
      name: '状态',
      width: '15%',
      isSpecial: true
    },
    {
      key: 'entered',
      name: '是否入场',
      width: '14%',
      isSpecial: true
    }
  ]
  tableData2: any = []
  total2 = 0
  listQuery2 = {
    page: 1,
    limit: 15
  }
  imgUrl = ''
  openWhichModal = ''
  remarkLoading = false
  visitorCarId = 0
  visitorCarRemark = ''
  // 预约访客Id
  reserveId = -1
  // 设备进组
  carGroupsIn: any = []
  // 设备出组
  carGroupsOut: any = []
  dialogIsShow = false
  dialogIsShow2 = false
  buttonLoading = false
  buttonLoading2 = false
  pickMinDate = ''
  pickOptions = {
    disabledDate: (v: any) => {
      if (this.pickMinDate !== '') {
        let maxTime = moment(this.pickMinDate).add(3, 'months')
        const minTime = moment(this.pickMinDate).subtract(3, 'months')
        if (maxTime > moment().endOf('day')) {
          maxTime = moment().endOf('day')
        }
        return moment(v) > maxTime || moment(v) < minTime
      }
      return moment(v) > moment().endOf('day')
    },
    onPick: (date: any) => {
      this.pickMinDate = date.minDate
      if (date.maxDate) {
        this.pickMinDate = ''
      }
    }
  }

  async beforeCreate() {
    // 获取相关字典
    // 访客访问状态
    this.visitReserveStatus = await getDicts('VISIT_RESERVE_STATUS')
  }

  mounted() {
    this.getCarGroupsOut()
    this.getCarGroupsIn()
    this.getCarList()
  }

  tabChange(index: number) {
    this.tabActive = index
    if (index === 0) {
      this.getCarList()
    } else {
      this.getOrderCarList()
    }
  }

  // 获取设备进入
  getCarGroupsIn() {
    this.$api.common
      .getAuthGroups({
        type: 2,
        size: '-1',
        direction: 1
      })
      .then((res: any) => {
        if (res.data.code === '200' && res.data.success) {
          this.carGroupsIn = res.data.data
        }
      })
  }

  // 获取设备离开
  getCarGroupsOut() {
    this.$api.common
      .getAuthGroups({
        type: 2,
        size: '-1',
        direction: 2
      })
      .then((res: any) => {
        if (res.data.code === '200' && res.data.success) {
          this.carGroupsOut = res.data.data
        }
      })
  }

  searchFn() {
    this.listQuery.page = 1
    this.getCarList()
  }

  // 获取访客车辆列表
  getCarList() {
    this.listLoading = true
    this.$api.visitor
      .getVisitorCarList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        enterDeviceId:
          this.enterDeviceId !== '' ? Number(this.enterDeviceId) : '',
        leaveDeviceId:
          this.leaveDeviceId !== '' ? Number(this.leaveDeviceId) : '',
        [this.searchModel2.key]: this.searchModel2.value,
        [this.searchModel.key === 'enterTime'
          ? 'enterStartTime'
          : 'leaveStartTime']: this.searchModel.value
          ? (this.searchModel.value as any)[0]
          : '',
        [this.searchModel.key === 'enterTime'
          ? 'enterEndTime'
          : 'leaveEndTime']: this.searchModel.value
          ? (this.searchModel.value as any)[1]
          : ''
      })
      .then((res: any) => {
        this.listLoading = false
        const data = res.data
        this.tableData = data.data
        this.total = data.total
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 获取已预约访客车辆列表
  getOrderCarList() {
    this.listLoading2 = true
    this.$api.visitor
      .getAppointVisitorCarList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        [this.searchModel4.key]: this.searchModel4.value,
        appointStartDate: this.searchModel3.value
          ? (this.searchModel3.value as any)[0]
          : '',
        appointEndDate: this.searchModel3.value
          ? (this.searchModel3.value as any)[1]
          : ''
      })
      .then((res: any) => {
        this.listLoading2 = false
        const data = res.data
        this.tableData2 = data.data
        this.total2 = data.total
      })
      .catch(() => {
        this.listLoading2 = false
      })
  }

  // 添加备注
  addRemark(remark: string) {
    this.remarkLoading = true
    this.$api.visitor
      .addCarRemark({
        id: this.visitorCarId,
        remark
      })
      .then((res: any) => {
        this.remarkLoading = false
        const data = res.data
        if (data.success) {
          this.$message.success('添加备注成功')
          this.getCarList()
          this.closeDialog()
        } else {
          this.$message.error(data.msg)
        }
      })
      .catch(() => {
        this.remarkLoading = false
        this.$message.error('添加备注失败')
      })
  }
  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, val)
  }
  // 批量导出
  doExport() {
    if (this.tabActive === 0) {
      this.dialogIsShow = true
    } else if (this.tabActive === 1) {
      this.dialogIsShow2 = true
    }
  }

  dialogConfirm() {
    this.buttonLoading = true
    const p = {
      exportType: 'visitCar',
      enterDeviceId:
        this.enterDeviceId !== '' ? Number(this.enterDeviceId) : '',
      leaveDeviceId:
        this.leaveDeviceId !== '' ? Number(this.leaveDeviceId) : '',
      [this.searchModel2.key]: this.searchModel2.value,
      [this.searchModel.key === 'enterTime'
        ? 'enterStartTime'
        : 'leaveStartTime']: this.searchModel.value
        ? (this.searchModel.value as any)[0]
        : '',
      [this.searchModel.key === 'enterTime'
        ? 'enterEndTime'
        : 'leaveEndTime']: this.searchModel.value
        ? (this.searchModel.value as any)[1]
        : ''
    }
    if (
      !(
        (this.searchModel.key === 'enterTime' ||
          this.searchModel.key === 'outTime') &&
        this.searchModel.value &&
        (this.searchModel.value as any)[0]
      )
    ) {
      const dayEnd = moment()
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss')
      const dayStart = moment(dayEnd)
        .subtract(3, 'months')
        .format('YYYY-MM-DD HH:mm:ss')
      p.enterStartTime = dayStart
      p.enterEndTime = dayEnd
    }
    getExportExcel(p, '访客车辆记录导出.xlsx')
      .then(() => {
        this.buttonLoading = false
        this.dialogIsShow = false
      })
      .catch(() => {
        this.buttonLoading = false
      })
  }

  dialogConfirm2() {
    this.buttonLoading2 = true
    const p = {
      exportType: 'visitReserve',
      [this.searchModel4.key]: this.searchModel4.value,
      appointStartDate: this.searchModel3.value
        ? (this.searchModel3.value as any)[0]
        : '',
      appointEndDate: this.searchModel3.value
        ? (this.searchModel3.value as any)[1]
        : ''
    }
    if (!(this.searchModel3.value && (this.searchModel3.value as any)[0])) {
      const dayEnd = moment().format('YYYY-MM-DD')
      const dayStart = moment(dayEnd)
        .subtract(3, 'months')
        .format('YYYY-MM-DD')
      p.appointStartDate = dayStart
      p.appointEndDate = dayEnd
    }
    getExportExcel(p, '已预约访客车辆记录导出.xlsx')
      .then(() => {
        this.buttonLoading2 = false
        this.dialogIsShow2 = false
      })
      .catch(() => {
        this.buttonLoading2 = false
      })
  }

  // 查看图片
  viewImg(url: string) {
    this.imgUrl = url
  }

  // 关闭图片查看
  closeImg() {
    this.imgUrl = ''
  }

  // 关闭对话框
  closeDialog() {
    this.dialogIsShow = false
    this.buttonLoading = false
    this.dialogIsShow2 = false
    this.buttonLoading2 = false
    this.openWhichModal = ''
  }
  // 操作对话框后统一回调
  afterOpt(data: any) {
    this.addRemark(data)
  }

  toHistory(carNo: string) {
    this.$router.push({
      path: '/visitors/car-history',
      query: {
        carNo
      }
    })
  }
}
